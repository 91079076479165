<template>
  <form-card
    description="Check your email and follow the instructions"
    title="Password link sent"
  >
    <Btn
      color="primary"
      class="q-mb-lg full-width"
      label="Login"
      to="/login"
    />
    <LinkBtn
      color="primary"
      label="Resend link"
      @click="$router.replace('/recover')"
      class="full-width"
    />
  </form-card>
</template>

<script>
import Btn from '@/components/buttons/Btn.vue';
import LinkBtn from '@/components/buttons/LinkBtn.vue';
import FormCard from '@/components/card/FormCard.vue';

export default {
  name: 'RecoverEmailSent',
  components: {
    FormCard,
    LinkBtn,
    Btn,
  },
};
</script>

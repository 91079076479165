






























import Vue, { VueConstructor } from 'vue';
import FormCard from '@/components/card/FormCard.vue';
import FieldEmail from '@/components/fields/FieldEmail.vue';
import ApiError from '@/components/ApiError.vue';
import formSubmit from '@/mixins/formSubmit';
import Btn from '@/components/buttons/Btn.vue';
import { FormWrapperRequest } from '@/types/form';
import LinkBtn from '@/components/buttons/LinkBtn.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'RecoverRequest',
  components: {
    LinkBtn,
    Btn,
    FormCard,
    FieldEmail,
    ApiError,
  },
  mixins: [formSubmit],
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          method: 'post',
          url: '/password/request',
          data: this.model,
        },
      };
    },
  },
  data() {
    return {
      model: {
        email: '',
      },
    };
  },
  methods: {
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);

      if (!this.hasError) {
        await this.$router.replace('/email-sent');
      }
    },
  },
});
